import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";

const Card = ({ data, formattedTitle }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setImageLoaded(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className="cursor-pointer border"
      onClick={() => {
        formattedTitle
          ? (window.location.href = `/top-earner/${formattedTitle}`)
          : (window.location.href = `/celebrities`);
      }}
    >
      <div className="md:h-[350px] h-[250px]">
        {imageLoaded ? (
          <img
            src={data?.image}
            alt=""
            loading="lazy"
            className="h-full w-full object-cover object-top"
          />
        ) : (
          <Skeleton
            className="md:h-[350px] h-[250px]"
            style={{
              backgroundColor: "#e0e0e0",
              opacity: 0.6,
            }}
          />
        )}
      </div>
      <p className="text-center md:text-[24px] text-[18px] font-semibold  mt-2">
        {data?.title}
      </p>
      <div className="flex justify-center text-gray-500 text-[14px]">
        {data?.sourceBy && <p>{data.sourceBy} | </p>}
        {data?.created_at && (
          <p>
            {new Date(data.created_at).toLocaleDateString("en-US", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })}
          </p>
        )}
      </div>
    </div>
  );
};

export default Card;
