import React, { useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

const SubBlogCard = ({ formattedTitle, blog, index, link = "blog" }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setImageLoaded(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleBlogClick = useCallback((formattedTitle) => {
    window.location.href = `/${link}/${formattedTitle}`;
  }, []);

  return (
    <div
      key={index}
      className="cursor-pointer border mt-2"
      onClick={() => handleBlogClick(formattedTitle)}
    >
      <div className="flex items-center gap-4">
        <div className="h-28 w-36">
          {imageLoaded ? (
            <img
              src={blog?.image}
              alt={blog?.title}
              className="h-full w-full object-cover"
              loading="lazy"
            />
          ) : (
            <Skeleton
              height="100%"
              width="100%"
              style={{
                backgroundColor: "#e0e0e0",
                opacity: 0.6,
              }}
            />
          )}
        </div>
        <div className="w-52">
          {blog?.created_at && (
            <p className="text-[14px] text-gray-500 max-[1100px]:text-[12px]">
              {new Date(blog?.created_at).toLocaleDateString("en-US", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              })}
            </p>
          )}
          <h4 className="line-clamp-3 tracking-[0.3px] font-medium hover:text-blue-800 text-[16px] max-[1100px]:text-[14px]">
            {blog?.title}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default SubBlogCard;
