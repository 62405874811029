import React from "react";
import { Link } from "react-router-dom";
import BackToTop from "../Components/backToTop";

const PrivacyPolicy = () => {
  return (
    <div className="px-5">
      <div className="container max-w-7xl mx-auto">
        <div className="mt-[3rem] mb-[15px] max-[768px]:mb-0 max-[768px]:mt-6">
          <h1
            className="flex justify-center text-[#000] md:text-[37px] font-semibold text-[30px]"
            style={{ fontFamily: "Playfair Display" }}
          >
            Privacy Policy
          </h1>
        </div>
        <div className="flex justify-center">
          <div className="">
            <div className="pt-2">
              <p className="md:text-[18px] text-[16px]">
                Welcome to the <b>billionairclubs.com</b> the website. We are
                dedicated to protecting your privacy and ensuring that your
                personal information is handled safely and responsibly. This
                Privacy Policy outlines how we collect, use, and protect your
                information when you visit our website. By using our site, you
                agree to the collection and use of information by this policy.
              </p>
            </div>
            <h2 className="lg:text-[22px] text-[20px] font-semibold pt-3">
              Information Collection
            </h2>
            <p className="md:text-[18px] text-[16px]">
              We collect several types of information for various purposes to
              provide and improve our service to you. Personal data such as your
              name, email address, and contact details are collected when you
              sign up for newsletters, register for events, or participate in
              surveys.
            </p>
            <p className="md:text-[18px] text-[16px]">
              We also collect usage data, including your IP address, browser
              type, pages visited, time spent on those pages, and other
              diagnostic data, through analytics tools and cookies. Cookies are
              small data files stored on your device to enhance your user
              experience.
            </p>

            <h2 className="lg:text-[22px] text-[20px] font-semibold  pt-[1rem] max-[768px]:!pt-3">
              Use of Information
            </h2>
            <p className="md:text-[18px] text-[16px]">
              The information we collect is used to provide and improve our
              services, personalize your experience on our website, and
              communicate with you effectively. We may use your personal
              information to send you newsletters, updates, and promotional
              materials that you have opted to receive.
            </p>
            <p className="md:text-[18px] text-[16px]">
              data helps us understand how our visitors interact with our
              website, allowing us to optimize content and user experience. Your
              information is not shared with third parties except as necessary
              to provide our services or as required by law.
            </p>
            <h2 className="lg:text-[22px] text-[20px] font-semibold  pt-[1rem] max-[768px]:!pt-3">
              Data Protection and Security Measures
            </h2>
            <p className="md:text-[18px] text-[16px]">
              We take the security of your personal information very seriously
              and implement a variety of security measures to protect your data
              from unauthorized access, alteration, disclosure, or destruction.
              These measures include encryption, secure servers, firewalls, and
              regular security audits. We ensure that any third-party service
              providers we work with adhere to strict data protection standards.
            </p>
            <p className="md:text-[18px] text-[16px]">
              In compliance with data protection regulations such as the General
              Data Protection Regulation (GDPR) and the California Consumer
              Privacy Act (CCPA), we ensure that your privacy rights are
              respected and upheld.
            </p>
            <h2 className="lg:text-[22px] text-[20px] font-semibold  pt-[1rem] max-[768px]:!pt-3">
              Cookies and Tracking Technologies
            </h2>
            <p className="md:text-[18px] text-[16px]">
              We use cookies and similar tracking technologies to collect and
              store information about your interactions with our website.
              Cookies help us understand your preferences based on previous or
              current site activity, enabling us to provide you with improved
              services. We use both session cookies, which expire once you close
              your browser, and persistent cookies, which remain on your device
              until you delete them.
            </p>
            <p className="md:text-[18px] text-[16px]">
              You can choose to have your computer warn you each time a cookie
              is being sent, or you can choose to turn off all cookies through
              your browser settings. However, if you disable cookies, some
              features of our site may not function properly. We also use
              third-party analytics tools such as Google Analytics to analyze
              website traffic and user behavior.
            </p>
            <h2 className="lg:text-[22px] text-[20px] font-semibold  pt-[1rem] max-[768px]:!pt-3">
              User Rights and Control Over Data
            </h2>
            <p className="md:text-[18px] text-[16px]">
              You have rights regarding your data, including the right to
              access, correct, delete, or restrict the processing of your data.
              You also have the right to data portability and to object to the
              processing of your data. If you wish to exercise any of these
              rights, please contact us at{" "}
              <Link
                to="mailto:support@8bitinfosys.com"
                className="!text-[#000] underline cursor-pointer"
              >
                privacy@8bitinfosys.com
              </Link>
              . We will respond to your request within a reasonable timeframe.
              Additionally, you can manage your cookie preferences through your
              browser settings and opt out of receiving marketing communications
              by following the unsubscribe instructions included in these
              communications.
            </p>
            <h2 className="lg:text-[22px] text-[20px] font-semibold  pt-[1rem] max-[768px]:!pt-3">
              Changes to This Privacy Policy
            </h2>
            <p className="md:text-[18px] text-[16px]">
              We may update our Privacy Policy from time to time to reflect
              changes in our practices or relevant laws. We will notify you of
              any significant changes by posting the new Privacy Policy on this
              page, with the "last updated" date indicated at the top.
            </p>
            <p className="md:text-[18px] text-[16px]">
              By continuing to use our website after these changes are posted,
              you acknowledge and agree to the updated policy. If you have any
              questions about this Privacy Policy or our data protection
              practices, please contact us at{" "}
              <a
                href="mailto:support@8bitinfosys.com"
                className="!text-[#000] underline cursor-pointer"
              >
                privacy@8bitinfosys.com
              </a>
              . Thank you for visiting our website and trusting us with your
              information.
            </p>
          </div>
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default PrivacyPolicy;
