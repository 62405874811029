import React from 'react';

const WebsiteDisclaimer = () => {
    return (
        <div className='px-5 mb-5'>
            <div className='max-w-[50rem] mx-auto p-7 bg-[#F4F4F4] min-h-[150px]'> 
                <h2 className='uppercase md:text-[18px] text-[15px] font-semibold'>Website Disclaimer</h2>
                <p className='lg:text-[10px] text-[8px] mt-3'>
                    The information provided by <b>8bitinfosys</b> ("We", "us" or "our") on 
                    <b className='text-blue-800 underline cursor-pointer'> https://billionairclubs.com/</b> is for general informational purposes only. 
                    All information on the Site is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.
                </p>
                <p className='lg:text-[9px] text-[8px] mt-3'>
                    UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.
                </p>
            </div>
        </div>
    );
}

export default WebsiteDisclaimer;