import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import BackToTop from "../Components/backToTop";
import ReactPaginate from "react-paginate";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import SubBlog from "../Components/HomeSubPage/Sub-blog";
import BetweenContent from "../Components/ads/BetweenContent";
import BelowFeaturedImages from "../Components/ads/BelowFeaturedImages";
import ParallaxCode from "../Components/ads/ParallaxCode";
import decrypt from "../Components/Common/DecryptData";
import LeftSideAd from "../Components/ads/LeftSideAd";
import RightSideAd from "../Components/ads/RightSideAd";

const SingleBillionaire = () => {
  const navigate = useNavigate();
  const [billionaire, setBillionaire] = useState({});
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [billionaires, setBillionaires] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const location = useLocation();
  const querySlug = location.pathname.split("/").pop();
  const formattedTitle = querySlug.replace(/-/g, " ");
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1440);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1440);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);

  const getOneBillionaire = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${Base_Url}api/billionaire/${formattedTitle}`
      );
      setLoading(false);
      if (res?.data) {
        const { iv, end } = res.data;

        const decryptedResponse = decrypt(iv, end);

        const parsedData = JSON.parse(decryptedResponse);

        setBillionaire(parsedData?.data);
      }
    } catch (error) {
      setLoading(false);
      setNotFound(true);
    }
  }, [formattedTitle]);

  useEffect(() => {
    if (formattedTitle) {
      getOneBillionaire();
    }
  }, [formattedTitle, getOneBillionaire]);

  const fetchAllBillionaires = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${Base_Url}api/get-billionaire?page=1&limit=100`
      );
      setLoading(false);
      if (response) {
        const fetchedBillionaires = response?.data?.data;
        setBillionaires(fetchedBillionaires);
        setPageCount(fetchedBillionaires?.length);

        if (formattedTitle) {
          const matchedIndex = fetchedBillionaires?.findIndex(
            (item) => item.name === formattedTitle
          );
          if (matchedIndex !== -1) {
            setCurrentPage(matchedIndex);
          }
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching billionaires:", error);
    }
  }, [formattedTitle]);

  useEffect(() => {
    fetchAllBillionaires();
  }, [fetchAllBillionaires]);

  const handlePageClick = async ({ selected }) => {
    setCurrentPage(selected);
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (billionaires && billionaires[selected]) {
      const nextSlug = billionaires[selected]?.name;
      if (nextSlug) {
        const formattedTitle = nextSlug?.replace(/\s+/g, "-");
        navigate(`/billionaires/${formattedTitle}`);
      }
    }
  };

  const MillionaireWithParallax = ({ description }) => {
    const BetweenContentAd = `{{BETWEEN_CONTENT_AD}}`;
    const parts = description?.split(BetweenContentAd);
    return (
      <>
        {parts?.map((part, index) => (
          <div key={index}>
            <div
              className="max-[768px]:text-justify md:text-[19px] text-[16px]"
              dangerouslySetInnerHTML={{ __html: part }}
            />
            {index < parts?.length - 1 && <BetweenContent />}
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      {isDesktop && (
        <>
          <LeftSideAd />
          <RightSideAd />
        </>
      )}
      <div className="flex justify-center bg-black text-white min-h-screen">
        {!notFound ? (
          <>
            {loading ? (
              <div className="flex justify-center items-center">
                <div className="loader1 my-5"></div>
              </div>
            ) : (
              <div className="max-[768px]:p-5 max-[768px]:pt-0 container max-w-7xl mx-auto md:px-0 px-3">
                <h1 className="text-center text-[35px] font-semibold mt-8 max-[768px]:text-[27px]">
                  {billionaire?.name}
                </h1>
                <div className="flex justify-center mt-4 mb-3">
                  <div className="h-[600px] max-[768px]:h-full">
                    <img
                      src={billionaire?.image}
                      alt={billionaire?.name}
                      className="h-full"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <div className="text-center">
                    {billionaire?.net_worth && (
                      <p className="md:text-[20px] font-bold text-[17px]">
                        <span className="text-[20px] font-medium max-[768px]:text-[17px]">
                          Net Worth:{" "}
                        </span>
                        {billionaire?.net_worth}
                      </p>
                    )}
                  </div>
                  <div className="text-center">
                    {billionaire?.country && (
                      <p className="md:text-[20px] font-bold text-[17px]">
                        <span className="text-[20px] font-medium max-[768px]:text-[17px]">
                          Country/Territory:{" "}
                        </span>
                        {billionaire?.country}
                      </p>
                    )}
                  </div>
                  <div className="text-center">
                    {billionaire?.industry && (
                      <p className="md:text-[20px] font-bold text-[17px]">
                        <span className="text-[20px] font-medium max-[768px]:text-[17px]">
                          Industry:
                        </span>
                        {billionaire?.industry}
                      </p>
                    )}
                  </div>
                </div>
                <ParallaxCode />
                <div className="flex gap-7 md:px-5 mt-10">
                  <div className="lg:w-[65%] w-full headline-description">
                    <MillionaireWithParallax
                      description={billionaire?.description}
                    />

                    <BetweenContent />
                    <div className="my-5 md:p-4">
                      <ReactPaginate
                        previousLabel={
                          <div
                            className={`flex items-center gap-2 text-white py-2 md:px-4 px-3 rounded-sm tracking-[0.3px] font-semibold md:text-[17px] text-[14px] ${
                              currentPage === 0
                                ? "bg-[#ffa484] cursor-not-allowed"
                                : "bg-[#F06939]"
                            }`}
                          >
                            <FaArrowLeftLong />
                            Previous
                          </div>
                        }
                        nextLabel={
                          <div
                            className={`flex items-center gap-2 text-white py-2 md:px-4 px-3 rounded-sm tracking-[0.3px] font-semibold md:text-[17px] text-[14px] ${
                              currentPage === pageCount - 1
                                ? "bg-[#ffa484] cursor-not-allowed"
                                : "bg-[#F06939]"
                            }`}
                          >
                            Next
                            <FaArrowRightLong />
                          </div>
                        }
                        pageCount={pageCount}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={0}
                        onPageChange={handlePageClick}
                        containerClassName={"flex justify-between mt-4"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"!hidden"}
                        forcePage={currentPage}
                      />
                    </div>
                  </div>
                  <div className="lg:w-[35%] lg:block hidden">
                    <div className="border border-[#cacaca]">
                      {isDesktop && (
                        <SubBlog
                          url={`${Base_Url}api/get-blog?limit=100&page=1`}
                          link="blog"
                          title="Recent Blog"
                        />
                      )}
                    </div>
                    <div className="py-10">
                      <BelowFeaturedImages />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center flex-col text-center">
            <h1 className="text-[120px] text-black max-[768px]:text-[94px] max-[768px]:mb-0">
              404
            </h1>
            <h2 className="text-3xl mb-1 text-black font-semibold max-[768px]:text-[25px]">
              Ooops... No Billionaire found!
            </h2>
            <div className="flex justify-center mt-3">
              <a href="/" className="no-underline">
                <button className="text-xl underline font-medium text-[#fff] bg-[#F06939] w-[220px] rounded-md h-[60px] mt-2">
                  Go Back
                </button>
              </a>
            </div>
          </div>
        )}

        <BackToTop />
      </div>
    </>
  );
};

export default SingleBillionaire;
