import React, { useEffect, useState } from "react";
import BackToTop from "../Components/backToTop";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import BelowFeaturedImages from "../Components/ads/BelowFeaturedImages";
import BetweenContent from "../Components/ads/BetweenContent";
import EndOfArticle from "../Components/ads/EndOfArticle";
import decrypt from "../Components/Common/DecryptData";
import LeftSideAd from "../Components/ads/LeftSideAd";
import RightSideAd from "../Components/ads/RightSideAd";
import LazyLoad from "react-lazyload";

const CarouselSection = React.lazy(() =>
  import("../Components/lifeStyle/CarouselSection")
);
const BillionairesFashionTrends = React.lazy(() =>
  import("../Components/lifeStyle/BillionairesFashionTrends")
);
const StreetStyle = React.lazy(() =>
  import("../Components/lifeStyle/StreetStyle")
);
const PerfumesFragrances = React.lazy(() =>
  import("../Components/lifeStyle/PerfumesFragrances")
);
const HairTrends = React.lazy(() =>
  import("../Components/lifeStyle/HairTrends")
);

const LifeStyle = () => {
  const [lifeStyleData, setLifestyleData] = useState();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1440);
  const [isVisible, setIsVisible] = useState(false);
  const [otherCelebrityBlogs, setOtherCelebrityBlogs] = useState();
  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1440);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  useEffect(() => {
    const getAllLifeStyleData = async () => {
      try {
        const response = await axios.get(`${Base_Url}api/life-style`);
        const response2 = await axios.get(
          `${Base_Url}api/life-style/top-earners`
        );

        const { iv, end } = response.data;
        const { iv: iv2, end: data } = response2.data;

        const decryptedDataString = decrypt(iv, end);
        const lifestyleBlog = decrypt(iv2, data);
        const decryptedData = JSON.parse(decryptedDataString); // Parse the decrypted JSON string
        if (decryptedData && lifestyleBlog) {
          setOtherCelebrityBlogs(JSON.parse(lifestyleBlog));
          setLifestyleData(decryptedData.data); // Use the decrypted data
        }
      } catch (error) {
        console.log("Error fetching life style data:", error);
      }
    };
    getAllLifeStyleData();
  }, []);

  return (
    <div>
      {isDesktop && isVisible && (
        <>
          <LeftSideAd />
          <RightSideAd />
        </>
      )}
      <div>
        <CarouselSection />
        <BelowFeaturedImages />
        <div className="container max-w-7xl mx-auto px-4">
          
          <LazyLoad height={200} offset={100}>
            <BillionairesFashionTrends lifeStyleData={lifeStyleData} />
          </LazyLoad>

          <BetweenContent />

          <LazyLoad height={200} offset={100}>
            <HairTrends />
          </LazyLoad>

          <EndOfArticle />

          <LazyLoad height={200} offset={100}>
            <StreetStyle otherCelebrityBlogs={otherCelebrityBlogs} />
          </LazyLoad>

          <EndOfArticle />

          <LazyLoad height={200} offset={100}>
            <PerfumesFragrances lifeStyleData={lifeStyleData} />
          </LazyLoad>
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default LifeStyle;
