import React, { useCallback } from "react";
import { MdLocationPin, MdOutlineMail, MdOutlinePhone } from "react-icons/md";
import WebsiteDisclaimer from "./HomeSubPage/WebsiteDisclaimer";
import { useLocation } from "react-router-dom";
import BelowFeaturedImages from "./ads/BelowFeaturedImages";

const Footer = () => {
  const location = useLocation();
  const isDisclaimerPage = location.pathname === "/disclaimer";

  // Memoized click handler for navigation links
  const handleNavigation = useCallback((path) => {
    window.location.href = path;
  }, []);

  // Memoized email click handler
  const handleEmail = useCallback(() => {
    window.location.href = "mailto:support@8bitinfosys";
  }, []);

  return (
    <>
      <div className="pt-5 pb-7">
        <BelowFeaturedImages />
      </div>
      {!isDisclaimerPage && <WebsiteDisclaimer />}
      <div className="py-12 bg-[#1a1a1a] text-white">
        <div className="container max-w-7xl mx-auto px-6">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6">
            <div>
              <h3 className="mb-4 md:text-2xl text-xl font-bold">
                Quick Links
              </h3>
              <ul>
                {[
                  "/about-us",
                  "/terms-conditions",
                  "/privacy-policy",
                  "/disclaimer",
                ].map((link, index) => (
                  <li
                    key={index}
                    className="mb-2 text-[#ffffffb3] md:text-lg text-[16px] cursor-pointer"
                    onClick={() => handleNavigation(link)}
                  >
                    {link.charAt(1).toUpperCase() +
                      link
                        .slice(2)
                        .replace("/", "")
                        .replace("-", " ")
                        .toLowerCase()}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="mb-4 md:text-2xl text-xl font-bold">Support</h3>
              <ul>
                {["/billionaires", "/celebrities", "/life-style", "/books"].map(
                  (link, index) => (
                    <li
                      key={index}
                      className="mb-2 text-[#ffffffb3] md:text-lg text-[16px] cursor-pointer"
                      onClick={() => handleNavigation(link)}
                    >
                      {link.charAt(1).toUpperCase() +
                        link
                          .slice(2)
                          .replace("/", "")
                          .replace("-", " ")
                          .toLowerCase()}
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="md:col-span-1 col-span-2">
              <h4 className="mb-4 md:text-2xl text-xl font-bold">
                Contact Info
              </h4>
              <div className="mb-4 flex md:text-lg text-[16px]">
                <MdLocationPin size={25} className="mr-2 mt-1" />
                <p className="text-[#ffffffb3] w-full">
                  209, Sunrise Complex, Savjibhai Korat Bridge, Lajamani chowk,
                  Mota Varachha, Surat, Gujarat 394101, India
                </p>
              </div>
              <div className="mb-4 flex">
                <MdOutlinePhone size={22} className="mr-2" />
                <p className="text-[#ffffffb3]">+91 90818 03333</p>
              </div>
              <div className="flex" onClick={handleEmail}>
                <MdOutlineMail size={22} className="mr-2" />
                <p className="text-[#ffffffb3] cursor-pointer">
                  8bitinfosys@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Footer);
